import inputmask from 'inputmask'
import { determineOS } from '../../base/js/utils'

const $phoneMask = document.getElementById('js-phone-mask')
const submitPhoneNode = document.getElementById('phoneForm');
const submitEmailNode = document.getElementById('emailForm');
const labelsNode = document.querySelectorAll(".form-partnership__label");
const inputsNode = document.querySelectorAll("input");
const buttonPhoneNode = document.getElementById('phoneBtn');
const buttonEmailNode = document.getElementById('emailBtn');
const spinnerPhoneNode = document.getElementById('spinnerPhone');
const spinnerEmailNode = document.getElementById('spinnerEmail');

// Need url
const URL = '/partners/become_a_client_form';

if ($phoneMask) {
    inputmask({
        mask: '+380 (99) 999-99-99',
        showMaskOnHover: false
    }).mask($phoneMask)
}

// show a message with a type of the input
function showMessage(input, message, type) {
    // get the small element and set the message
    const msg = input.parentNode.querySelector("small");

    msg.innerText = message;
    // update the class for the input

    input.className = type ? "success" : "error";

    return type;
}

function showError(input, message) {
    return showMessage(input, message, false);
}

function showSuccess(input) {
    return showMessage(input, "", true);
}

function validateEmail(input, invalidMsg) {
    const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const email = input.value.trim();
    if (!emailRegex.test(email)) {
        return showError(input, invalidMsg);
    }
    return showSuccess(input);
}

function validatePhone(input, invalidMessage) {
    const phoneRegex = /^\+380\d{3}\d{2}\d{2}\d{2}$/;

    let phone = input.value.trim().replace(/[^0-9+]/g, "");

    if (!phoneRegex.test(phone)) {
        return showError(input, invalidMessage)
    }

    return showSuccess(input);
}

const PHONE_REQUIRED = "Будь ласка, вкажіть телефон"
const EMAIL_INVALID = "Будь ласка, перевірте email";

function showPopup(id) {
    document.getElementById(id).classList.add('popup__active');
    document.getElementById(id).classList.remove('popup__hide');

    buttonPhoneNode.classList.remove('form-partnership__button--hide-text');
    buttonPhoneNode.classList.add('form-partnership__button--show-text');

    buttonEmailNode.classList.remove('form-partnership__button--hide-text');
    buttonEmailNode.classList.add('form-partnership__button--show-text');

    spinnerPhoneNode.classList.remove('spinner--active');
    spinnerPhoneNode.classList.add('spinner--hide');
    spinnerEmailNode.classList.remove('spinner--active');
    spinnerEmailNode.classList.add('spinner--hide');
}

submitPhoneNode.addEventListener('submit', async (event) => {
    event.preventDefault();

    const os = determineOS();
    if (os == 'ios' || os == 'android') {
        window.location.href = '/app-link';
        return
    }

    let phoneValid = validatePhone(submitPhoneNode.elements["phone"], PHONE_REQUIRED)

    if (phoneValid) {
        buttonPhoneNode.classList.add('form-partnership__button--hide-text');
        buttonPhoneNode.classList.remove('form-partnership__button--show-text');
        spinnerPhoneNode.classList.add('spinner--active');
        spinnerPhoneNode.classList.remove('spinner--hide');

        Array.from(inputsNode).forEach((item) => {
            item.style.opacity = "0.8";
            item.style.color = "rgba(3, 13, 37, 0.5)";
        });

        let response = await fetch(URL,
            {
                method: 'POST',
                body: new URLSearchParams(new FormData(submitPhoneNode)),
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }
        ).catch(() => showPopup('error'))

        if (response && response.ok) {
            showPopup('success');
        } else {
            showPopup('error');
        }

    }
});

submitEmailNode.addEventListener('submit', async (event) => {
    event.preventDefault();

    let emailValid = validateEmail(submitEmailNode.elements["email"], EMAIL_INVALID)

    if (emailValid) {
        buttonEmailNode.classList.add('form-partnership__button--hide-text');
        buttonEmailNode.classList.remove('form-partnership__button--show-text');
        spinnerEmailNode.classList.add('spinner--active');
        spinnerEmailNode.classList.remove('spinner--hide');

        Array.from(inputsNode).forEach((item) => {
            item.style.opacity = "0.8";
            item.style.color = "rgba(3, 13, 37, 0.5)";
        });

        let response = await fetch(URL,
            {
                method: 'POST',
                body: new URLSearchParams(new FormData(submitEmailNode)),
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }
        ).catch(() => showPopup('error'))

        if (response && response.ok) {
            showPopup('success');
        } else {
            showPopup('error');
        }

    }
});

inputsNode.forEach((input) => {
    input.addEventListener('focusin', () => {
        showSuccess(input);
    });
})

labelsNode.forEach((label) => {
    label.addEventListener('click', () => {
        labelsNode.forEach((item) => {
            if (item === label) {
                if(item.innerText === 'Телефон') {
                    submitPhoneNode.classList.add('form-partnership__form--active');
                    submitPhoneNode.classList.remove('form-partnership__form--hide');
                    submitEmailNode.classList.add('form-partnership__form--hide');
                    submitEmailNode.classList.remove('form-partnership__form--active');
                } else {
                    submitPhoneNode.classList.add('form-partnership__form--hide');
                    submitPhoneNode.classList.remove('form-partnership__form--active');
                    submitEmailNode.classList.add('form-partnership__form--active');
                    submitEmailNode.classList.remove('form-partnership__form--hide');
                }

                label.classList.add('form-partnership__label--active')
            } else {
                item.classList.remove('form-partnership__label--active');
            }

            submitPhoneNode.reset();
            submitEmailNode.reset();

            Array.from(inputsNode).forEach((item) => {
                item.style.opacity = "1";
                item.style.color = "#261D00";
            });
        })
    })
})


function closePopup(id, className) {
    document.getElementById(id).addEventListener('click', (event) => {
        event.preventDefault();

        document.getElementById(className).classList.add('popup__hide');
        document.getElementById(className).classList.remove('popup__active');

        if (id === 'closeSuccessPartnership') {
            submitPhoneNode.reset();
            submitEmailNode.reset();
        }

        Array.from(inputsNode).forEach((item) => {
            item.style.opacity = "1";
            item.style.color = "#261D00";
        });
    });
}

closePopup('closeSuccessPartnership', 'success');
closePopup('closeErrorPartnership', 'error');
