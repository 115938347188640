import inputmask from 'inputmask'

const $phoneMask = document.getElementById('js-phone-mask')
const submitNode = document.getElementById('callbackForm');
const formOrder = document.getElementsByClassName('form-order');
const inputsNode = document.querySelectorAll("input");
const buttonNode = document.getElementsByClassName('form-order__button');
const spinnerNode = document.getElementsByClassName('spinner');

const URL = '/partners/become_a_partner_form'

if ($phoneMask) {
    inputmask({
        mask: '+380 (99) 999-99-99',
        showMaskOnHover: false
    }).mask($phoneMask)
}

// show a message with a type of the input
function showMessage(input, message, type) {
    // get the small element and set the message
    const msg = input.parentNode.querySelector("small");

    msg.innerText = message;
    // update the class for the input

    input.className = type ? "success" : "error";

    return type;
}

function showError(input, message) {
    return showMessage(input, message, false);
}

function showSuccess(input) {
    return showMessage(input, "", true);
}

function hasValue(input, message) {
    if (input.value.trim() === "") {
        return showError(input, message);
    }
    return showSuccess(input);
}

function validateEmail(input, invalidMsg) {
    const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const email = input.value.trim();
    if (!emailRegex.test(email)) {
        return showError(input, invalidMsg);
    }
    return showSuccess(input);
}

function validatePhone(input, invalidMessage) {
    const phoneRegex = /^\+380\d{3}\d{2}\d{2}\d{2}$/;

    let phone = input.value.trim().replace(/[^0-9+]/g, "");

    if (!phoneRegex.test(phone)) {
        return showError(input, invalidMessage)
    }

    return showSuccess(input);
}

const NAME_REQUIRED = "Будь ласка, вкажіть ім'я";
const COMPANY_REQUIRED = "Будь ласка, вкажіть назву компанії";
const PHONE_REQUIRED = "Будь ласка, вкажіть телефон"
const EMAIL_INVALID = "Будь ласка, перевірте email";

function showPopup(id) {
    document.getElementById(id).classList.add('popup__active');
    document.getElementById(id).classList.remove('popup__hide');

    buttonNode[0].classList.remove('form-order__button--hide-text');
    buttonNode[0].classList.add('form-order__button--show-text');

    spinnerNode[0].classList.remove('spinner--active');
    spinnerNode[0].classList.add('spinner--hide');
}

submitNode.addEventListener('submit', async (event) => {
    event.preventDefault();

    let nameValid = hasValue(submitNode.elements["name"], NAME_REQUIRED);
    let companyValid = hasValue(submitNode.elements["company"], COMPANY_REQUIRED)
    let phoneValid = validatePhone(submitNode.elements["phone"], PHONE_REQUIRED)
    let emailValid = validateEmail(submitNode.elements["email"], EMAIL_INVALID);

    if (nameValid && emailValid && companyValid && phoneValid) {

        buttonNode[0].classList.add('form-order__button--hide-text');
        buttonNode[0].classList.remove('form-order__button--show-text');
        spinnerNode[0].classList.add('spinner--active');
        spinnerNode[0].classList.remove('spinner--hide');

        Array.from(inputsNode).forEach((item) => {
            item.style.opacity = "0.8";
            item.style.color = "rgba(3, 13, 37, 0.5)";
        });

        let response = await fetch(URL,
            {
                method: 'POST',
                body: new URLSearchParams(new FormData(submitNode)),
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }
        ).catch(() => showPopup('error'))

        if (response && response.ok) {
            showPopup('success');
        } else {
            showPopup('error');
        }

    }
})

inputsNode.forEach((input) => {
    input.addEventListener('focusin', () => {
        showSuccess(input);
    });
})


function closePopup(id, className) {
    document.getElementById(id).addEventListener('click', (event) => {
        event.preventDefault();

        document.getElementById(className).classList.add('popup__hide');
        document.getElementById(className).classList.remove('popup__active');

        if (id === 'closeSuccess') {
            document.getElementById('callbackForm').reset();
        }

        Array.from(inputsNode).forEach((item) => {
            item.style.opacity = "1";
            item.style.color = "#261D00";
        });
    });
}

closePopup('closeSuccess', 'success');
closePopup('closeError', 'error');
