export const determineOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || navigator.opera;

    if (/android/i.test(userAgent)) {
        return 'android';
    }

    if (/iPhone|iPod/i.test(userAgent)) {
        return 'ios';
    }

    return 'unknown';
};
