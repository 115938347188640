document.addEventListener('DOMContentLoaded', () => {
    const medNode = document.getElementsByClassName('med--js');
    const sectionWhiteNode = document.getElementsByClassName('bg-white-custom');
    const sectionBgLinearNode = document.getElementsByClassName('bg-linear-custom');
    const children = Array.from(medNode[0].children);

    window.addEventListener('scroll', () => {
        if (medNode) {
            let scroll = window.pageYOffset || document.documentElement.scrollTop;

            if (scroll === 0) {
                children.forEach((item) => {
                    if (item.classList.contains('icon-med-black')) {
                        item.parentNode.classList.remove('med__is-bg');
                        if (window.matchMedia('(max-width: 760px)').matches) {
                            item.parentNode.parentNode.style.left = "50%"
                        } else {
                            item.parentNode.parentNode.style.left = "0"
                        }
                    }
                })
            } else {
                children.forEach((item) => {
                    if (item) {
                        item.parentNode.classList.add('med__is-bg');
                        if (window.matchMedia('(max-width: 760px)').matches) {
                            item.parentNode.parentNode.style.left = "50%"
                        } else {
                            item.parentNode.parentNode.style.left = "0"
                        }
                    }
                })
            }

            if (sectionBgLinearNode[0]) {
                if (scroll > (sectionWhiteNode[0].scrollHeight + sectionBgLinearNode[0].scrollHeight) - 50) {
                    children.forEach((item) => {
                        if (item.classList.contains('icon-med-black')) {
                            item.classList.add('med__is-hide');
                            item.classList.remove('med__is-show');
                        } else {
                            item.classList.add('med__is-show');
                            item.classList.remove('med__is-hide');
                        }
                    })
                } else {
                    children.forEach((item) => {
                        if (item.classList.contains('icon-med-black')) {
                            item.classList.add('med__is-show');
                            item.classList.remove('med__is-hide');
                        } else {
                            item.classList.add('med__is-hide');
                            item.classList.remove('med__is-show');
                        }
                    })
                }
            } else {
                if (scroll > sectionWhiteNode[0].scrollHeight - 100) {
                    children.forEach((item) => {
                        if (item.classList.contains('icon-med-black')) {
                            item.classList.add('med__is-hide');
                            item.classList.remove('med__is-show');
                        } else {
                            item.classList.add('med__is-show');
                            item.classList.remove('med__is-hide');
                        }
                    })
                } else {
                    children.forEach((item) => {
                        if (item.classList.contains('icon-med-black')) {
                            item.classList.add('med__is-show');
                            item.classList.remove('med__is-hide');
                        } else {
                            item.classList.add('med__is-hide');
                            item.classList.remove('med__is-show');
                        }
                    })
                }
            }
        }
    })
});

