import { determineOS } from './utils.js';

export const registerEvents = () => {
    document.addEventListener('DOMContentLoaded', () => {
        const os = determineOS();
        let elements = []
        if (os == 'android' || os == 'ios') {
            elements = [
                ...document.getElementsByClassName('form-partnership__block'),
                ...document.getElementsByClassName('form-partnership__subtitle'),
                ...document.getElementsByClassName('form-partnership__labels'),
                ...document.getElementsByClassName('form-partnership__subhead'),
                ...document.getElementsByClassName('form-partnership__stores')
            ];
        } else {
            elements = document.getElementsByClassName('form-partnership__service');
        }
        for (let element of elements) {
            element.classList.add('hide');
        }
    });
}
