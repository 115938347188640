document.addEventListener('DOMContentLoaded', () => {
    const instrumentSales = document.getElementsByClassName('instrument-sales-partnership');
    const cardInformation = document.getElementsByClassName('card-information-partnership');
    const headerOffset = 45;
    const elementPosition = cardInformation[0].getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    let isFinishAnimation = false;

    if (window.pageYOffset === 0 || document.documentElement.scrollTop === 0) {
        window.addEventListener('scroll', () => {
            let scroll = window.pageYOffset || document.documentElement.scrollTop;

            if (!isFinishAnimation) {
                if (instrumentSales[0].clientHeight > scroll && scroll > 0) {
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    });

                    Array.from(cardInformation[0].children).forEach((item) => {
                        if (item.classList.contains('card-information-partnership__card')) {
                            item.classList.add('card-information-partnership__card--transform');
                        }
                    })

                    isFinishAnimation = true;
                }
            }
        })
    } else {
        Array.from(cardInformation[0].children).forEach((item) => {
            if (item.classList.contains('card-information-partnership__card')) {
                item.classList.add('card-information-partnership__card--transform');
            }
        })
    }


})
