// Vendor
import '../../vendor/vendor'

// Components
import '../../components/instrument-sales-partnership/instrument-sales-partnership'
import '../../components/form-partnership/form-partnership'

import './utils'
import { registerEvents } from './events'

registerEvents()
